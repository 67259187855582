<template>
  <div>
    <!-- <h4>Videos</h4> -->
    <b-skeleton-wrapper :loading="reqStatus == 'loading'">
      <template #loading>
        <b-skeleton-img
          height="50vh"
          animation="throb"
          no-aspect
        ></b-skeleton-img>
      </template>

      <div class="fave-container" v-if="favorites.length > 0">
        <b-row no-gutters align-v="stretch">
          <b-col cols="12" lg="8" xl="8" align-self="stretch">
            <div
              class="media-container"
              :style="{height: `${mediaContainerHeight}px`}">
              <div
                class="player"
                :class="`wistia_embed wistia_async_${favorites[0].wistia_id}
                  playlistLinks=auto videoFoam=true`"
                id="video-player"
                style="width: 100%; height: 60vh;"
              ></div>
            </div>
          </b-col>
          <b-col
            class="favorites"
            :class="{'d-flex' : breakpoint === 'xl'}"
            cols="12"
            lg="4"
            xl="4"
            align-self="stretch">
            <ol :style="{height: `${mediaContainerHeight}px`}">
              <li
                :class="{active : activeVideo.wistia_id === fave.wistia_id}"
                :id="`list-${fave.wistia_id}`"
                v-for="(fave, index) in favorites"
                :key="fave.media_id">
                <b-icon
                  class="delete-icon"
                  icon="trash-fill"
                  title="Remove Favorite"
                  @click.prevent="confirmDelete(index, fave)"
                ></b-icon>
                <a
                  class="fave-link"
                  :class="`link-${fave.wistia_id}`"
                  :href="`#wistia_${fave.wistia_id}?autoPlay=true`"
                  @click.prevent="setActiveVideo(fave)">
                  <b-row no-gutters>
                    <b-col cols="4">
                      <img
                        class="img-fluid"
                        :src="`${fave.thumbnail}&image_play_button=true`"
                        :alt="`Play ${fave.media_title}`"
                      />
                    </b-col>
                    <b-col cols="8">
                      <p v-if="breakpoint === 'xl'">
                        {{ fave.media_title | truncate(20) }}
                      </p>
                      <p v-else> {{ fave.media_title | truncate(30) }} </p>
                      <p> {{ fave.media_length | formatTime }} </p>
                    </b-col>
                  </b-row>
                </a>
              </li>
            </ol>
          </b-col>
        </b-row>
      </div>
      <AppNoContent class="no-content" v-if="reqStatus === 'error'">
        <p class="mb-0 title">This space looks empty</p>
        <p class="mb-0">Fill it up by liking clips from our
          <router-link to="/categories">library</router-link>!
        </p>
      </AppNoContent>
    </b-skeleton-wrapper>
    
    
    <v-dialog />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FilterMixin from '@/mixins/FilterMixin';
  import LevelUpMixin from '@/mixins/LevelUpMixin';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import MyMediaMixin from '@/mixins/MyMediaMixin';

  export default {
    mixins : [
      FilterMixin,
      LevelUpMixin,
      BreakpointMixin,
      MyMediaMixin,
    ],
    data() {
      return {
        secondsWatched : 0,
        activeVideo    : {},
      }
    },
    computed : {
      ...mapGetters({
        user      : 'user/user',
        profile   : 'profile/info',
        favorites : 'profile/videoFavorites',
        initVideo : 'profile/initVideoFave',
        reqStatus : 'profile/videoFaveStatus',
      }),
    },
    components : {
      AppNoContent : () => import('@/components/layout/AppNoContent'),
    },
    watch : {
      initVideo(val) {
        if (val)
          this.setActiveVideo(val);
      },
    },
    methods : {

      /**
       * Show Confirmation Dialog
       * @param data
       * @param index
       */
      confirmDelete(index, data) {
        this.$modal.show('dialog', {
          title   : 'Video Favorite',
          text    : `Remove <b>${data.media_title}</b>?`,
          buttons : [
            {
              title   : 'Yes',
              handler : () => {
                this.deleteFavorite(index, data);
                this.$modal.hide('dialog');
              },
            },
            {
              title : 'No',
            },
          ],
        });
      },

      /**
       * Perform Delete Favorite
       * @param index
       * @param data
       */
      async deleteFavorite(index, data) {
        const lastIndex = this.favorites.length - 1;
        const links = document.getElementsByClassName('fave-link');

        // Check if video to remove is currently active/playing
        if (data.wistia_id === this.activeVideo.wistia_id &&
          this.favorites.length != 1) {
          if (index < lastIndex) {
            // Set next Video as active
            this.setActiveVideo(this.favorites[index + 1]);
            links[index  + 1].click();
          }
          if (index === lastIndex) {
            // Set first Video as active
            this.setActiveVideo(this.favorites[0]);
            links[0].click();
          }
          this.$nextTick(() => {
            this.favorites.splice(index, 1);
          });
        }

        // Delete Favorite in the database
        await this.$http.delete('api/myvideos', {
          data : {
            mediaId : data.media_id,
          },
        }).then(() => {
          this.$store.dispatch('profiles/getVideoFavorites');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : data.media_title + ' has been removed from Favorites',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Set Active Video
       * @param data
       */
      setActiveVideo(data) {
        this.activeVideo = data;

        this.$analytics.fbq.event('ViewContent', {
          'page'        : 'my-favorites',
          'platform'    : 'website',
          'user_id'     : this.$store.getters['user/user'].user_id,
          'video_title' : data.media_title,
          'video_id'    : data.media_id,
        });
      },

      gtag30sec(that) {
        that.$gtag.event('video_30s_listen', {
          'media_id'    : that.activeVideo.media_id,
          'media_title' : that.activeVideo.media_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        });
      },
      gtagtotalsec(that) {
        that.$gtag.event('video_total_listen', {
          'media_id'              : that.activeVideo.media_id,
          'media_title'           : that.activeVideo.media_title,
          'platform'              : 'website',
          'environment'           : process.env.VUE_APP_ENVIRONMENT,
          'music_listen_duration' : that.secondsWatched,
        });
      },
    },
    created() {
      window.addEventListener('beforeunload', () => {
        if(this.secondsWatched > 0)
          this.gtagtotalsec(this);
      })
    },
    mounted() {
      const that = this;

      // Create element for wistia external js
      const wistiaScript = document.createElement('script');
      wistiaScript.setAttribute(
        'src',
        '//fast.wistia.com/assets/external/E-v1.js');
      document.head.appendChild(wistiaScript);

      window._wq = window._wq || [];
      // eslint-disable-next-line no-undef
      _wq.push({
        id        : "_all",
        onHasData : (video) => {
          video.unbind('secondchange')
            .bind('secondchange', () => {
              this.secondsWatched = video.secondsWatched();
            });
        },
        onReady : (video) => {
          //console.log("I got a handle on the video...")
          video.bind('end', function() {
            //console.log('END TRIGGERED......');
            if (that.secondsWatched >= 30)
              that.gtag30sec(that);
            that.gtagtotalsec(that);

            if (that.secondsWatched >= 60) {
              const event = (that.user.first_video === 0) ?
                'FIRST_VIDEO' : 'WATCHED_VIDEO';
              
              // Add Points to User
              that.addPoints(event);
            }
          });
        },
      });
    },
    beforeDestroy() {
      // this.initVideo = {};
      if(this.secondsWatched > 0)
        this.gtagtotalsec(this);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .fave-container {
    padding: 0.7em;
    background-color: rgba(0, 129, 207, 0.9);
    // background-color: #54bbff;
    // border-radius: 0.7em;
  }

  .player {
    max-height: 54.5vh;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .favorites {
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.7);

    ol {
      margin-bottom: 0;
      padding-left: 0;
      width: 100%;
      list-style-type: none;

      li {
        padding: 0.5em;
        border-bottom: 0.8px dashed rgba(255, 255, 255, 0.2);
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        background-color: #333;
        p {
          &:last-child { color: rgba(255,255,255,0.5);}
        }
      }
      .fave-link {
        text-decoration: none;
      }
    }

    p {
      margin-bottom: 0;
      padding-left: 0.5em;
      color: #fff;
      font-size: 0.75em !important;
      font-weight: normal !important;

      &:last-child {
        font-size: 0.65em !important;
        color: rgba(255,255,255,0.5);
      }
    }

    .delete-icon {
      z-index: 1;
      right: 3%;
      position: absolute;
      padding: 0.35rem;
      color: #fff;
      background-color: $primary-color !important;
      border-radius: 1rem;
      font-size: 1.3em !important;
      opacity: 0.7;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  .no-content {
    .title {
      color: $tooltip-color !important;
      font-family: 'WickedMouse' !important;
    }
    p {
      color: $tooltip-color;
      font-size: 1.25em !important;
    }
  }

  /* Custom Scrollbar */
  .favorites::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(255,255,255,0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }

  .favorites::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: $nav-pink-1;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .favorites {
      p {
        font-size: 0.85em !important;
        &:last-child {
          font-size: 0.75em !important;
        }
      }
      .delete-icon {
        font-size: 1.5em !important;
      }
    }
    .no-content {
      p { font-size: 1em !important; }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .favorites {
      p {
        font-size: 1em !important;
        &:last-child {
          font-size: 0.85em !important;
        }
      }
      .delete-icon {
        font-size: 1.5em !important;
      }
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .favorites {
      p {
        font-size: 1.25em !important;
        &:last-child {
          font-size: 1em !important;
        }
      }
      .delete-icon {
        font-size: 1.75em !important;
      }
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .favorites {
      p {
        font-size: 0.8em !important;
        &:last-child {
          font-size: 0.75em !important;
        }
      }
      .delete-icon {
        font-size: 1.25em !important;
      }
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .no-content {
      p { font-size: 1.1em !important; }
    }
  }
  @media only screen and (min-width: $special) {}
  @media only screen and (min-width: $xl2-min) {
    .no-content {
      p { font-size: 1.25em !important; }
    }
    /* Custom Scrollbar */
    .favorites::-webkit-scrollbar {
      width: 12px;
      background-color: rgba(255,255,255,0.5);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }

    .favorites::-webkit-scrollbar-thumb {
      // border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: $nav-pink-1;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    /* Custom Scrollbar */
    .favorites::-webkit-scrollbar {
      width: 12px;
      background-color: rgba(255,255,255,0.5);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }

    .favorites::-webkit-scrollbar-thumb {
      // border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: $nav-pink-1;
    }
  }

</style>